import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import Content from '../components/Content';
import styled from 'styled-components';

const ArticleContainer = styled.div`
  text-align: center;
  margin: 0 1rem;
  overflow: hidden;
`;

class ArchivePageTemplate extends React.Component {
  render() {
    const { pageContext } = this.props;

    return (
      <Layout>
        <Helmet>
          <title>Legacy of Steel</title>
        </Helmet>
        <Content>
          <ArticleContainer>
            <div
              key={pageContext.id}
              dangerouslySetInnerHTML={{ __html: pageContext.html }}
            />
          </ArticleContainer>
        </Content>
      </Layout>
    );
  }
}

export default ArchivePageTemplate;
